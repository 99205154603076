import { useState, useEffect, useMemo, useRef } from "react";
import { useDispatch } from "react-redux";
import { Button, Upload, Spin, message, Image, Drawer, Radio } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import { chatOptions, LAB_CHAT, PATIENT_CHAT } from "utilities/constants";
import TextEditor from "components/shared/TextEditor/TextEditor";
import { socket, getCommentHistoryService, uploadChatFile, sendMessage } from "api/chat";
import { checkTextEditorContent, removeTrailingEmptyTagsFromString } from "utilities/IPR";
import { imagesTypes, CHAT_FILE_SIZE } from "../../../../constants";
import ImageViewer from "react-simple-image-viewer";
import { FileOutlined } from "@ant-design/icons";
import PlaceholderChat from "components/shared/Icon/PlaceholderChat";
import AddAttachment from "components/shared/Icon/AddAttachment";
import { changeCommentBoxState } from "services/redux/actions/clinicActions";
import SendChat from "components/shared/Icon/SendChat";
import ChatActive from "components/shared/Icon/ChatActive";
import ChatInactive from "components/shared/Icon/ChatInactive";
import dayjs from "dayjs";
import "../chat.scss";
import { primaryColor } from "colors-config";
import Message from "./Message";

const ChatDrawer = ({
  clinicId,
  patientId,
  partneredLabs,
  patientName,
  showCommentBox,
  openPatientChatTab,
  disableCommentBox,
  clinicInfo,
  open,
  setOpen,
  setUnreadChat,
}) => {

  
  const chatInfo = {
    clinic_id: clinicId,
    lab_id: partneredLabs[0]?.lab_id,
    patient_id: patientId,
    lab_name: partneredLabs[0]?.lab_name,
  };
  const [inputText, setInputText] = useState("");
  const [selectedRadioKey, setSelectedRadioKey] = useState(openPatientChatTab ? PATIENT_CHAT : LAB_CHAT);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [commentHistory, setCommentHistory] = useState([]);
  // const [partnerLabCount, setPartnerLabCount] = useState(partneredLabs);
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState([]);
  // const [unreadCount, setUnreadCount] = useState(0);
  const [showCommentsModal, setShowCommentsModal] = useState(
    typeof showCommentBox !== "undefined" ? showCommentBox : false
  );
  const [showToolBar, setShowToolBar] = useState(true);

  // const showDrawer = () => {
  //   setOpen(true);
  // };
  const onClose = () => {
    console.log("openCommentBox");
    dispatch(changeCommentBoxState(false))
    setOpen(false);
  };

  const onChange4 = ({ target: { value } }) => {
    setSelectedRadioKey(value);
  };

  const sendHandler = async () => {
    let message = inputText.toString();

    if (!message) {
      return;
    }

    const haveText = checkTextEditorContent(message);

    if (!haveText) {
      return;
    }
    message = removeTrailingEmptyTagsFromString(message);

    sendMessage(
      {
        meta: {
          receiver_id: selectedRadioKey === PATIENT_CHAT ? chatInfo.patient_id : chatInfo.lab_id,
          sender_id: chatInfo.clinic_id,
          patient_id: chatInfo.patient_id,
          module: "clinic",
        },

        fileContent: "",

        message,
      },
      (response) => {
        if (response && response?.status === "ok") {
          setInputText("");
        } else {
          message.error("server not responding! try again");
        }
        scrollToBottom();
      }
    );
  };

  // useEffect(() => {
  //   document.querySelectorAll(".message-container img").forEach((item) => {
  //     item.addEventListener("click", (event) => {
  //       setCurrentImage([item.src]);
  //       setIsViewerOpen(true);
  //     });
  //   });
  // }, [commentHistory]);

  const closeImageViewer = () => {
    setCurrentImage([]);
    setIsViewerOpen(false);
  };

  const isImage = (mimeType) => {
    const extension = mimeType.split("/")[1];
    return imagesTypes.includes(extension);
  };

  useEffect(() => {
    socket.emit("joinRoom", chatInfo);
    socket.emit("joinRoom", {
      clinic_id: chatInfo.clinic_id,
      patient_id: patientId,
    });

    socket.on("message", (comment) => {
      const mappedComment = commentMetaMapping(comment);
      setCommentHistory((prevMessages) => [...prevMessages, mappedComment]);
      scrollToBottom();
    });

    return () => {
      socket.off("message", () => {
        console.log("off socket");
      });
    };
  }, []);

  const scrollToBottom = () => {
    setTimeout(() => {
      const chatWindow = document.getElementById("chatWindow");
      if (chatWindow?.scrollHeight > 0) {
        chatWindow.scrollTop = chatWindow?.scrollHeight;
      }
    }, 700);
  };

  useEffect(() => {
    // setPartnerLabCount(partneredLabs);
    if (partneredLabs.length > 0 && typeof clinicId !== "undefined") {
      const index = partneredLabs.findIndex((x) => x.lab_id === clinicId);
      if (index > -1) {
        setSelectedRadioKey(LAB_CHAT);
      }
    }
  }, [partneredLabs]);

  useEffect(() => {
    if (clinicId) {
      // if (selectedRadioKey === PATIENT_CHAT) {
      //   // setRecieverName(patientName);

      // } else {
      //   partneredLabs.forEach((element, index) => {
      //     if (selectedRadioKey === LAB_CHAT) {
      //       setRecieverId(element.lab_id);
      //       setRecieverName(element.lab_name);
      //     }
      //   });
      // }
      getCommentHistory();
    }
  }, [selectedRadioKey, showCommentsModal, clinicId, patientId, partneredLabs]);

  const commentMetaMapping = (comment) => {
    const date = dayjs(comment.created_date).format("DD-MM-YYYY hh:mm A");
    const commentCopy = { ...comment, created_date: date };

    if (!comment?.metaData) {
      return commentCopy;
    }
    const metaData = JSON.parse(comment.metaData);

    if (!metaData.mimetype) {
      return commentCopy;
    }
    if (isImage(metaData.mimetype)) {
      return {
        ...commentCopy,
        comment_description: metaData.url,
        type: "image",
        metaData,
      };
    } else {
      return {
        ...commentCopy,
        comment_description: metaData.url,
        type: "file",
        metaData,
      };
    }
  };
  const getCommentHistory = async () => {
    setIsLoading(true);
    try {
      const response = await getCommentHistoryService({
        senderId: chatInfo.clinic_id,
        receiverId:
          selectedRadioKey === PATIENT_CHAT ? chatInfo.patient_id || patientId : chatInfo.lab_id,
        patientId: patientId,
        // comment_description: inputText,
        // module: "clinic",
      });
      const mappedComments = response.data.body.data.map((comment) => {
        const mappedComment = commentMetaMapping(comment);

        return mappedComment;
      });

      setCommentHistory(mappedComments);

      scrollToBottom();
    } catch (error) {
      message.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  function urlify(text) {
    const urlRegex = /https?:\/\/[^\s<>"']+(?![^<>]*>)/g;
    return text.replace(urlRegex, function (url, b, c) {
      var url2 = c == "www." ? "http://" + url : url;
      return '<a class="hyperlinksChat" href="' + url2 + '" target="_blank">' + url + "</a>";
    });
  }

  const replaceNewLine = (comment_description) => {
    if (comment_description) {
      comment_description = comment_description.replaceAll("\n", "<br>");
    }
    return comment_description;
  };

  const onFileChange = async (file) => {
    if (file?.size > CHAT_FILE_SIZE) {
      return message.error("One or more files should not be greater than 150MB.");
    }
    const receiver = selectedRadioKey === PATIENT_CHAT ? "patient" : "lab";

    const data = new FormData();
    data.append("file", file);
    data.append("name", file.name);
    data.append("sender", "clinic");
    data.append("lab_id", chatInfo.lab_id);
    data.append("clinic_id", chatInfo.clinic_id);
    data.append("patient_id", chatInfo.patient_id);

    data.append("receiver", receiver);

    try {
      setIsLoading(true);
      await uploadChatFile(data);
    } catch (err) {
      console.log("err");
      message.error("failed to send file");
    } finally {
      setIsLoading(false);
    }

    // socket.emit('file_upload', data);

    // Prevent upload
    return false;
  };

  const handleDownload = (url, fileName) => {
    const link = document.createElement("a");
    link.href = url;
    link.target = "_blank";
    link.download = fileName;
    link.click();
    link.remove();
  };

  const showContent = (commentData) => {
    if (commentData?.type === "image") {
      return <Image width={200} src={commentData.comment_description} />;
    }

    if (commentData?.type === "file") {
      return (
        <div
          title={commentData.metaData.name}
          onClick={() => handleDownload(commentData.comment_description, commentData.metaData.name)}
          className="file-container"
        >
          <FileOutlined style={{ fontSize: "25px" }} />
          <span className="file-name">{commentData.metaData.name}</span>
        </div>
      );
    }

    return (
      <div
        className="no-margin"
        dangerouslySetInnerHTML={{
          __html: urlify(replaceNewLine(commentData.comment_description)),
        }}
      />
    );
  };

  const tabBody = (
    <Spin spinning={isLoading}>
      <div style={{ overflow: "auto", height: "100%" }} id="chatWindow">
        {commentHistory.length > 0 ? (
          commentHistory.map((data) => {
            
            return (
              <Message
                key={data.id}
                isSender={data.sender_id?.includes("CLINIC")}
                name={
                  data.sender_id?.includes("CLINIC") ? clinicInfo?.clinicName : data.sender_id?.includes("PATIENT") ? patientName:chatInfo?.lab_name
                }
                content={showContent(data)}
                date={data.created_date}
                primaryColor={primaryColor}
              />
            )
          })
        ) : (
          <div className="empty-msgs-container">
            <div className="empty">
              <PlaceholderChat style={{ fontSize: "150px", color: primaryColor }} />
              <p>No Chat with {selectedRadioKey === PATIENT_CHAT ? "Patient" : "Lab"}</p>
            </div>
          </div>
        )}
      </div>
    </Spin>
  );

  return (
    <div className="chat-window-container">
      <Drawer title="" onClose={onClose} open={open} width="500" className="chat-window-drawer">
        <div className="chat-window-content">
          <div className="header">
            <div className="close">
              <CloseOutlined onClick={onClose} />
            </div>
            <div className="chat-radio-group">
              <Radio.Group
                options={chatOptions}
                onChange={onChange4}
                value={selectedRadioKey}
                optionType="button"
                buttonStyle="solid"
              />
            </div>
          </div>
          <div className="content">{tabBody}</div>
          <div className="footer">
            <div className="comment-editor">
              <div>
                <Upload
                  multiple
                  customRequest={() => {}}
                  showUploadList={false}
                  beforeUpload={onFileChange}
                >
                  <Button shape="circle" icon={<AddAttachment />} className="upload-icon" />
                </Upload>
              </div>
              <TextEditor
                imageCallback={onFileChange}
                placeholder={`Write your message to ${
                  selectedRadioKey === PATIENT_CHAT ? "Patient" : "Lab"
                }`}
                editoInput={inputText}
                setEditorInput={setInputText}
                hideToolbar={showToolBar}
              />
              <div className="send-icons">
                <div onClick={() => setShowToolBar(!showToolBar)}>
                  {showToolBar ? (
                    <ChatInactive style={{ fontSize: "1.5rem" }} />
                  ) : (
                    <ChatActive style={{ fontSize: "1.5rem" }} />
                  )}
                </div>
                <div onClick={sendHandler}>
                  <SendChat style={{ marginLeft: "10px", fontSize: "1.5rem", color: primaryColor }} />
                </div>
              </div>
            </div>
            {isViewerOpen && (
              <ImageViewer
                src={currentImage}
                currentIndex={0}
                onClose={closeImageViewer}
                disableScroll={false}
                backgroundStyle={{
                  backgroundColor: "rgba(0,0,0,0.9)",
                }}
                closeOnClickOutside={true}
              />
            )}
          </div>
        </div>
      </Drawer>
    </div>
  );
};

export default ChatDrawer;
